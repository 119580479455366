.container-2{
  height: 100vh;
  width: 100vw;
  background-color: black;
  /* background: linear-gradient(360deg, rgb(218, 218, 218) 0%, rgba(0,0,0,1) 3%); */
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: absolute;
}

.video-min{
  filter: drop-shadow(0 0 0.85rem grey);
  width: 100%;
  border-radius: 10px;
}

.video-full{
  height: 100%;
}

.ios-card{
  filter: drop-shadow(0 0 0.75rem grey);
  border-radius: 10px;
  background-color: white;
}

input[type='range']::-webkit-slider-thumb {
  width: 15px;
  -webkit-appearance: none;
  height: 15px;
  border-radius: 20px;
  border: 1px solid #ffffff;
  background: #1e4e9b;
}

/* html,body,#root { 
  background: url('./utils/assets/back.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
} */

html,body,#root { 
  background: linear-gradient(360deg, rgb(235, 234, 234) 0%, rgba(0,0,0,1) 0%);
  /* background: linear-gradient(360deg, rgb(235, 234, 234) 0%, rgba(0,0,0,1) 30%); */
}

html, body, #root {
  height: 100%;
  margin: 0;
  -webkit-touch-callout: none; 
    -webkit-user-select: none; 
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none; 
            user-select: none;
}

.webkit-filter{
  -webkit-filter: drop-shadow(0 0 0.85rem grey);
}

